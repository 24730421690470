<template>
  <div class="cell-wrapper">
    <img :src="img" class="image" />
    <div class="right">
      <div class="title h2 single-line">{{title}}</div>
      <div class="content">{{content}}</div>
      <div class="function-footer">
        <div class="button" @click="handleClick">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    img() {
      if (this.info.itemPicUrl) {
        return this.info.itemPicUrl
      }
      if (this.info.pictureUrl) {
        return this.info.pictureUrl
      }
      return ''
    },
    title() {
      if (this.info?.title) {
        return this.info.title
      }
      if (this.info?.institutesName) {
        return this.info.institutesName
      }
      if (this.info?.enterpriseName) {
        return this.info.enterpriseName
      }
      console.log('this.info.title = ', this.info.title)
      return ''
    },
    content() {
      var c = ''
      if (this.info?.content) {
        c = this.info.content
      }
      if (this.info?.institutesDetail) {
        c = this.info.institutesDetail
      }
      if (this.info?.enterpriseIntroduction) {
        c = this.info.enterpriseIntroduction
      }
      if (this.info?.institutesDetailText) {
        c = this.info.institutesDetailText
      }
      return c
    },
  },
  created() { },
  mounted() {
  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 120px;
  display: flex;
  align-items: stretch;
  padding: 30px;
  border-bottom: #dfdfdf 1px solid;
  .image {
    width: 200px;
    height: 100%;
    margin-right: 20px;
    object-fit: cover;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .title {
      height: 30px;
    }
    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .function-footer {
      display: flex;
      justify-content: right;
      .button {
        height: 35px;
        width: 150px;
        border: solid 1px #dfdfdf;
        line-height: 40px;
        cursor: pointer;
        border-radius: 15px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
.cell-wrapper:hover {
  color: #27a5f9;
  background: #eef8ff;
  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
