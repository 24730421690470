<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="招院引所"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <!-- 入驻院所 -->
        <div class="right-list-box" v-if="menu_id=='ruzhuyuansuo'">
          <cell-ruzhuqiye
            v-for="item in dataList1"
            :key="item.id"
            @handleClick="yuansuoDetail"
            :info="item"
          ></cell-ruzhuqiye>
          <div class="footer">
            <div class="left">共 {{total1}} 条</div>

            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :current-page.sync="queryParams1.pageNum"
              :page-size="queryParams1.pageSize"
              @current-change="getList1"
            ></el-pagination>
          </div>
        </div>
        <!-- 招引政策 -->
        <div class="right-list-box" v-if="menu_id=='zhaoyinzhengce'">
          <article-list @handleClick="articleClick" :data="dataList2"></article-list>
          <div class="footer">
            <div class="left">共 {{total2}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total2"
              :current-page.sync="queryParams2.pageNum"
              :page-size="queryParams2.pageSize"
              @current-change="getList2"
            ></el-pagination>
          </div>
        </div>
        <!-- 招引服务 -->
        <div class="right-list-box" v-if="menu_id=='zhaoyinfuwu'">
          <div style="width: 90%;margin: 0 auto;background-color: #2867b8;">
            <img
              src="../../static/images/cloud/zhaoyinfuwu.png"
              style="width: 100%;height: 300px;object-fit: contain;"
              object-fit="contain"
            />
          </div>
        </div>
        <!-- 入驻申请 -->
        <div class="right-list-box" v-if="menu_id=='ruzhushenqing'">
          <div style="width: 80%;margin: 20px auto;">
            <div
              style="height: 44px;background-color: #E8EDF8;text-align: center;line-height: 44px;margin-bottom: 20px;"
              class="h2"
            >入驻申请</div>
            <el-form ref="form" :model="form" label-width="120px" label-suffix=":">
              <el-form-item label="姓名">
                <el-input v-model="form.contactName" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="您的联系方式">
                <el-input v-model="form.contactPhone" placeholder="请输入您的电话号码"></el-input>
              </el-form-item>
              <el-form-item label="院所名称">
                <el-input v-model="form.institutesName" placeholder="请输入院所名称"></el-input>
              </el-form-item>
              <el-form-item label="院所地址">
                <el-input v-model="form.institutesAddress" placeholder="请输入院所地址"></el-input>
              </el-form-item>
              <el-form-item label="院所领域">
                <el-input v-model="form.field" placeholder="请输入院所领域"></el-input>
              </el-form-item>
              <el-form-item label="院所简介">
                <el-input
                  type="textarea"
                  v-model="form.institutesIntroduction"
                  placeholder="请输入院所的简介"
                  rows="4"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="入住需求">
                <el-input
                  type="textarea"
                  v-model="form.requirement"
                  placeholder="请简要描述您的入驻需求"
                  rows="4"
                  resize="none"
                ></el-input>
              </el-form-item>
              <div style="display: flex;justify-content: center;">
                <el-button @click="goback" style="margin-right: 50px;width: 150px;">取消</el-button>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  style="width: 150px;"
                  :loading="formLoading"
                >提交</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import cellRuzhuqiye from './components/cell-ruzhuqiye.vue'
export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    cellRuzhuqiye
  },
  data() {
    return {
      menuData: [{
        'name': '入驻院所',
        'code': 'ruzhuyuansuo'
      },
      {
        'name': '招引政策',
        'code': 'zhaoyinzhengce'
      },
      {
        'name': '招引服务',
        'code': 'zhaoyinfuwu'
      },
      {
        'name': '入驻申请',
        'code': 'ruzhushenqing'
      }
      ],
      menu_id: 'ruzhuyuansuo',
      form: {},
      formLoading: false,
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 6,
      },
      // 总条数
      total1: 0,
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
      },
      // 总条数
      total2: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id&&menu_id!='undefined') {
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList1()
    this.getList2()
  },
  methods: {
    getList1() {
      const params = {
        ...this.queryParams1,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.institutesInfoList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    getList2() {
      const params = {
        ...this.queryParams2,
        'areaType': '1',
        'policyType': '2',
      }
      this.$api.solicitationCloud.institutesPolicyList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    goback() {
      this.$router.back()
    },
    onSubmit() {
      if (!this.form.contactName) {
        this.$message.error('请输入联系人');
        return
      }
      if (!this.form.contactPhone) {
        this.$message.error('请输入联系电话');
        return
      }
      if (!this.form.institutesName) {
        this.$message.error('请输入院所名称');
        return
      }
      this.formLoading = true
      this.$api.solicitationCloud.institutesApply(this.form).then((res) => {
        this.formLoading = false
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
        this.formLoading = false
      })
    },
    articleClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-zyys/articledetail', query: { id: item.id, 'detailType': 'soli-policy' } })
    },
    yuansuoDetail(item) {
      this.$router.push({ path: '/solicitationCloud/page-zyys/yuansuoDetail', query: { id: item.id, 'detailType': 'soli-yuansuo' } })
    },
  }
}
</script>
<style scoped lang="less">
.policy-article-wrapper {
  display: flex;
  flex-direction: row;
}
</style>
